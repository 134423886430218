import React from 'react'
import "./footer.css"

export default function Footer() {
  return (
    <div>
        <footer>
            <h4>Footer</h4>
            <h4>Linkedin</h4>
            <h4>Facebook</h4>
        </footer>
    </div>
  )
}
